<template>
  <v-container fluid class="order-detail d-flex flex-column px-0" v-if="order">
    <CategoryTitle :category="category" />
    <v-btn
      color="primary"
      width="250"
      class="mt-5"
      outlined
      large
      v-bind:to="{ name: 'Orders' }"
    >
      <v-icon class="mr-3">$prev</v-icon>
      <span v-html="$t('orders.button.backToOrders')"></span>
    </v-btn>
    <div v-if="showPayment" class="pa-3 grey lighten-2 mb-5 rounded-sm">
      <span class="d-block text-h2 line-height-1 mt-3">{{
        $t("order.retryPayment")
      }}</span>
      <PaymentTypeList
        mode="order"
        :order-id="order.orderId"
        :options="paymentTypeListOptions"
      />
    </div>

    <v-row justify="space-between" class="order-detail-wrapper my-3">
      <v-col cols="12" sm="4">
        <CartInfoServiceCard :shippingAddress="order.shippingAddress" />
      </v-col>
      <v-col cols="12" sm="4">
        <CartInfoAddressCard :shippingAddress="order.shippingAddress" />
      </v-col>
      <v-col cols="12" sm="4">
        <CartInfoTimeslotCard
          v-if="order"
          :shippingAddress="order.shippingAddress"
          :timeslot="order.timeslot"
          class="cart-info-timeslot-card"
        />
      </v-col>
    </v-row>
    <OrderDetailInfo
      class="card my-5"
      :order="order"
      @addAllToCart="addAllToCart"
      @deleteOrder="deleteOrder"
      @editOrder="editOrder"
      @showPayment="showPayment = !showPayment"
    />

    <v-card outlined>
      <CheckoutSummary
        :orderCart="order"
        class="card summary default--text"
        :isOrderDetail="true"
        :canDeleteGift="false"
      />
    </v-card>

    <OrderDetailTable
      :suborders="order.suborders"
      :order="order"
      class="mt-4 mt-sm-12"
    />

    <v-btn
      color="primary"
      width="250"
      class="mt-5"
      outlined
      large
      v-bind:to="{ name: 'Orders' }"
    >
      <v-icon class="mr-3">$prev</v-icon>
      <span v-html="$t('orders.button.backToOrders')"></span>
    </v-btn>
  </v-container>
</template>
<style lang="scss">
.order-detail {
  &-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    @media screen and (min-width: 600px) {
      display: flex;
    }
    .card {
      padding: 10px 20px !important;
      box-sizing: border-box;
      background-color: var(--v-grey-lighten2) !important;
      border: 1px solid var(--v-grey-lighten2) !important;
      border-radius: 8px;
      &:hover {
        background-color: var(--v-primary-lighten2);
      }
      &.cart-info {
        padding: 35px 0 27px 12px !important;
        .text,
        .value {
          line-height: 24px !important;
        }
        @media #{map-get($display-breakpoints, 'sm-only')} {
          padding: 20px !important;
        }
      }
    }
  }
  .remove-gift {
    display: none;
  }
}

@media print {
  .order-detail {
    .cart-info-cards {
      flex-direction: row !important;
      gap: 12px !important;
    }

    .cart-info-cards div:first-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import OrderDetailTable from "@/components/orders/OrderDetailTable.vue";
import OrderDetailInfo from "@/components/orders/OrderDetailInfo.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import categoryMixins from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapState, mapActions } from "vuex";

export default {
  name: "Order",
  data() {
    return {
      review: {
        rating: 3,
        comment: "",
        active: 1,
        title: "",
        noteId: null,
        productId: -1
      },
      order: null,
      valid: true,
      showPayment: false,
      requiredRules: [v => !!v || "Campo obbligatorio"],
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        mainBackgroundColor: "grey lighten-2",
        titleStyle: "default--text text-uppercase payment-type-list-title",
        back: {
          label: "Torna Indietro",
          class: "paymenbt-back-button",
          show: true
        }
      }
    };
  },
  mixins: [categoryMixins, reload],
  components: {
    OrderDetailInfo,
    OrderDetailTable,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CartInfoServiceCard,
    CheckoutSummary,
    CategoryTitle,
    PaymentTypeList
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    // canIRateOrder() {
    //   return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    // },
    enableSave() {
      return !this.valid;
    },
    serviceImg() {
      return this.$t(
        "navbar.service." +
          this.order.shippingAddress.deliveryServiceId +
          ".icon",
        {
          color: "color"
        }
      );
    }
  },
  methods: {
    // getTimeslotTime(time) {
    //   if (time) {
    //     const parseTime = this.am_pm_to_hours(time);
    //     var d = new Date();
    //     var timeMatch = parseTime.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    //     d.setHours(parseInt(timeMatch[1]) + (timeMatch[3] ? 12 : 0));
    //     d.setMinutes(parseInt(timeMatch[2]) || 0);
    //     const timeOnly = this.$dayjs(d).format("HH:mm");
    //     return timeOnly;
    //   } else return "";
    // },
    // am_pm_to_hours(time) {
    //   var hours = Number(time.match(/^(\d+)/)[1]);
    //   var minutes = Number(time.match(/:(\d+)/)[1]);
    //   var AMPM = time.match(/\s(.*)$/)[1];
    //   if (AMPM == "PM" && hours < 12) hours = hours + 12;
    //   if (AMPM == "AM" && hours == 12) hours = hours - 12;
    //   var sHours = hours.toString();
    //   var sMinutes = minutes.toString();
    //   if (hours < 10) sHours = "0" + sHours;
    //   if (minutes < 10) sMinutes = "0" + sMinutes;
    //   return sHours + ":" + sMinutes;
    // },
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    async addAllToCart({ orderId }) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllFromOrder")
      });
      if (res) {
        await this.addProductsFromOrder(orderId);
      }
    },
    async deleteOrder({ orderId }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });

      if (res) {
        await OrderService.deleteOrder(orderId);
        AnalyticsService.refund(this.order);
        await this.reload(orderId);
      }
    },
    async editOrder({ orderId }) {
      try {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.confirmOrderEdit")
        });
        if (res) {
          await OrderService.editOrder(orderId);
          this.loadCart();
          this.$router.push({
            name: "Cart"
          });
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.errorOnOrderEditConfirm")
        });
        return null;
      }
    },
    async reload(orderId) {
      this.order = await OrderService.getOrder(orderId);
      if (this.order.isPayable) {
        this.showPayment = true;
      }
    }
  },
  created() {
    this.reload(this.$route.params.orderId);
  }
};
</script>
